body {
  font-family: "Gilroy-Regular", sans-serif;
}

/* Sms font */
@font-face {
  font-family: "Gilroy-Regular";
  src: url("./fonts/Gilroy-Regular.woff2") format("woff2");
}

/* div in index.js */
.container {
  padding-right: 18%;
  padding-left: 18%;
  margin-right: auto;
  margin-left: auto;
}

h1,
h2,
h3,
h4,
h5 {
  color: rgb(23, 44, 83) !important;
}

.outlined-button {
  border: 1px solid rgb(23, 44, 83) !important;
  color: rgb(23, 44, 83) !important;
  background-color: rgb(255, 255, 255) !important;
  border-radius: 5px !important;
  font-weight: bold !important;
  cursor: pointer !important;
}

.full-button {
  border: 1px solid rgb(23, 44, 83) !important;
  color: white !important;
  background-color: rgb(23, 44, 83) !important;
  border-radius: 5px !important;
  font-weight: bold !important;
  cursor: pointer !important;
}

/* This is here because the OSM map does not show on the page without it, something to do with position absolute. TODO: fix */
.fix-this {
  color: white !important;
}
